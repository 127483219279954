.upcomingEventsSlides {
  min-height: 40vh;
  /* border: solid red 2px; */
}

.upcomingEventsSlides .header h3 {
  text-transform: capitalize;
  font-family: days one;
  text-align: center;
}

.upcomingEventsSlides .swiper-slide a {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.upcomingEventsSlides .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upcomingEventsSlides .swiper-slide .image {
  flex: 1;
  height: 100%;
  /* border: solid red 2px; */
}
.upcomingEventsSlides .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 15px var(--color-peace);
}
.upcomingEventsSlides .swiper-slide .eventDetail {
  flex: 1;
  color: var(--color-white);
  padding-right: 5%;
}

.upcomingEventsSlides .swiper-slide .event-slide {
  display: flex;
  background-color: var(--color-fulfillment);
  width: 80%;
  min-height: 25rem;
  gap: 2rem;
}
.upcomingEventsSlides .swiper-slide .event-slide .header {
  margin-bottom: 1rem;
}
.upcomingEventsSlides .swiper-slide .event-slide h3 {
  text-align: left;
  letter-spacing: 1;
  font-weight: 100;
  font-size: x-large;
  margin-bottom: 0;
  padding-bottom: 0;
}

.upcomingEventsSlides .swiper-slide .event-slide .txtContent {
  text-align: left;
}

.upcomingEventsSlides .slide-swiper .swiper-button-prev::after {
  display: none;
}
.upcomingEventsSlides .slide-swiper .swiper-button-next::after {
  display: none;
}

.upcomingEventsSlides .swiper-button-prev,
.upcomingEventsSlides .swiper-button-next {
  border: none !important;
}

.upcomingEventsSlides .swiper-button-next {
  background-image: url("./../../assets/images/next_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5rem;
  height: 2.5rem;
}

.upcomingEventsSlides .swiper-button-prev {
  background-image: url("./../../assets/images/prev_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (max-width: 600px) {
  .upcomingEventsSlides .swiper-slide .event-slide {
    flex-direction: column;
    gap: 0.5rem;
    width: 70%;
  }
  .upcomingEventsSlides .swiper-slide .eventDetail {
    padding: 5%;
  }
  .upcomingEventsSlides .swiper-button-next {
    width: 1.5rem;
    height: 1.5rem;
  }

  .upcomingEventsSlides .swiper-button-prev {
    width: 1.5rem;
    height: 1.5rem;
  }
}
