#sub-header {
  padding-top: 8rem;
  background-color: var(--color-bg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
#sub-header .container {
  min-height: 20rem;

  display: flex;
  gap: 4rem;
}

#sub-header .container .header-content {
  width: 70%;
  /* border: solid red 2px; */
}
#sub-header .container .header-content h1 {
  font-size: 3rem;
  color: var(--color-peace);
  text-transform: capitalize;
}

#sub-header .container .header-content p {
  width: 100%;
  margin-top: 2rem;
  color: var(--color-peace);
  text-align: left;
}

#sub-header .right-foot-img {
  width: 30%;
  background: url("./../../assets/images/foot.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  transform: rotate(30deg);
}

#help {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#help .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#help .container article {
  width: 70%;
}
#help .container article h2 {
  text-align: center;
  color: var(--color-bg);
  margin-bottom: 1rem;
  margin-top: 4rem;
  font-family: days one;
}

#donate_info {
  background-color: (--color-white);
}
#donate_info .container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 0 20%;
  /* border: solid red 2px; */
}
#donate_info .container .sub-list {
  /* margin: 2rem 0; */
  flex: 1;
  width: 30%;
  color: var(--color-peace);
  /* border: solid red 2px; */
}
/* #donate_info .container .sub-list div {
  margin: 2rem 0;
} */
#donate_info .container .sub-list p {
  font-weight: 400;
  color: var(--color-bg);
  font-size: x-large;
}
#donate_info .container .sub-list a {
  color: var(--color-peace);
}

#donate_info .container .donate-right {
  width: 30%;
  flex: 1;
  color: var(--color-joy);
  /* margin: 4rem 0; */
  /* border: solid red 2px; */
}
/* #donate_info .container .donate-right div {
  margin: 2rem 0;
} */
#donate_info .container .donate-right p {
  font-weight: 400;
  color: var(--color-happiness);
  font-size: x-large;
}
#donate_info .container .donate-right a {
  color: var(--color-peace);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and(max-width:600px) {
  #sub-header .container {
    position: relative;
    flex-direction: column;
  }
  #sub-header .container .header-content {
    width: 100%;
  }
  #sub-header .container .header-content h1 {
    font-size: 3rem;
    color: var(--color-peace);
  }

  #sub-header .container .header-content p {
    width: 100%;
    margin-top: 2rem;
    color: var(--color-peace);
  }

  #sub-header .container .header-content .right-foot-img {
    position: relative;
    right: -8rem;
    top: 2rem;
    width: 90%;
    min-height: 15rem;
    overflow: hidden;
  }

  #help .container article {
    width: 100%;
  }

  #donate_info .container {
    flex-direction: column;
  }
  #donate_info .container .sub-list {
    width: 90%;
    margin-bottom: 0;
  }

  #donate_info .container .donate-right {
    width: 90%;
    margin-top: 0;
  }
}
