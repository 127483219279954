.eventsSection {
  padding-top: 6rem;
}

.eventsSection .container .header {
  margin: 3rem 0;
}

.eventsSection .container .header h1 {
  text-transform: uppercase;
  text-align: center;
  color: var(--color-confidence);
  font-size: 3.5rem;
}
