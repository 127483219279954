#head {
  padding: 8rem 0 0 0;
  min-height: 100vh;
}
#head .container .contents {
  display: flex;
}

#head .container .contents .left {
  width: 50%;
}

#head .container .contents .left .top {
  height: 10rem;
  margin-bottom: 3rem;
}
#head .container .contents .left .top h1 {
  font-size: 3rem;
  color: var(--color-bg);
  text-transform: capitalize;
}
#head .container .contents .left .top small {
  color: var(--color-happiness);
  font-size: 2rem;
}

#head .container .contents .left .bottom {
  position: relative;
}
#head .backdrop {
  position: absolute;
  height: 35rem;
  width: 45rem;
  background-color: var(--color-joy);
  border-radius: 2rem 10rem 1.5rem 4rem;
  transform: rotate(20deg);
  left: -18rem;
  z-index: -1;
}
#head .container .contents img {
  width: 60%;
  margin-left: 5%;
  filter: grayscale(100%);
}
#head .container .contents img:hover {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: url("./../../assets/images/f2hh.png"); /* Firefox 4+ */
  filter: gray; /* IE 6-9 */
}

#head .container .contents .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#head .container .contents .right p {
  margin: 1rem 0;
}
.member-group {
  /* border-radius: 2rem; */
  padding: 0 2%;
  display: flex;
  gap: 2rem;
  /* flex-direction: row;
  align-items: center;
  justify-content: center; */
}

.member-group .container .member-profile {
  display: flex;
  gap: 2rem;
  background: var(--color-happiness);
  margin-bottom: 2rem;
  color: var(--color-white);
  padding: 2rem;
  /* border: solid blue 2px; */
}

.member-group .container .member-profile:nth-child(2) {
  background: var(--color-joy);
}

.member-group .container .member-profile:nth-child(3) {
  background: var(--color-joy-variant);
}
.member-group .container .member-profile:nth-child(4) {
  background: var(--color-confidence);
}
.member-group .container .member-profile:nth-child(5) {
  background: var(--color-fulfillment);
}
.member-group .container .member-profile:nth-child(7) {
  background: var(--color-confidence);
}

.member-group .container .member-profile:nth-child(8) {
  background: var(--color-fulfillment);
}

.member-group .container .member-profile:nth-child(10) {
  background: var(--color-joy);
}

.member-group .container .member-profile .left {
  flex: 1;
  display: flex;
  justify-content: center;
}

.member-group .container .member-profile .profile-avatar {
  width: 20rem;
  height: 20rem;
  border-radius: 1rem;
  transition: all 400ms ease;
  overflow: hidden;
  /* border: solid red 2px; */
}

.member-group .container .member-profile .profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;

  transition: all 400ms ease;
}

.member-group .container .member-profile .profile-avatar img:hover {
  transform: scale(1.1);
}

.member-group .container .member-profile .right {
  flex: 1;
  padding: 2rem;
}

.member-group .container .member-profile .right h3 {
  text-transform: capitalize;
  font-family: days one;
  font-weight: 200;
}

#alex {
  background-color: var(--color-joy) !important;
}

#nketiah {
  background-color: var(--color-peace) !important;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  #head {
    padding: 8rem 0 0 0;
    min-height: 100vh;
  }
  #head .container .contents {
    flex-direction: column;
  }

  #head .container .contents .left {
    width: 100%;
  }

  #head .container .contents .left .top h1 {
    font-size: 3rem;
    color: var(--color-bg);
    text-transform: capitalize;
  }
  #head .container .contents .left .top small {
    color: var(--color-happiness);
    font-size: 2rem;
  }

  #head .container .contents .left .bottom {
    position: relative;
  }
  #head .backdrop {
    position: absolute;
    height: 26.4rem;
    width: 35rem;
    background-color: var(--color-joy);
    border-radius: 2rem 10rem 1.5rem 4rem;
    transform: unset;
    left: -8rem;
    z-index: -1;
  }
  #head .container .contents img {
    width: 100%;
    margin-left: 0%;
    filter: grayscale(100%);
  }

  #head .container .contents .right {
    width: 100%;
  }

  .member-group .container .member-profile {
    flex-direction: column;
  }

  .member-group .container .member-profile .left {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .member-group .container .member-profile .right {
    padding: 0;
  }
}
