.notFoundSection {
    /* background-color: var(--color-white); */
    min-height: 80vh;
    padding-top: 8rem;
    padding-bottom: 4rem;
    position: relative;
}

.notFoundSection .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.notFoundSection .container .notFoundWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notFoundSection .container .notFoundWrapper h1 {
    font-style: normal;
    font-weight: 200;
    font-size: 84px;
    text-transform: capitalize;
    line-height: 4.5rem;
    color: var(--green-color);
}

.notFoundSection .container .notFoundWrapper h3 {
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 15px;
}

.notFoundSection .container .notFoundWrapper p {
    width: 100%;
}

.notFoundSection .container .notFoundWrapper p a {
    color: var(--green-color);
}