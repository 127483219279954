.slider {
  margin: auto;
  margin-top: 2rem;
  height: 450px;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.slider .slide-track {
  display: flex;
  width: calc(350px * 10);
  animation: scroll 60s linear infinite;
}
.slider .slide-track:hover {
  animation-play-state: paused;
}
.slider .slide {
  height: 20rem;
  width: 20rem;
  display: flex;
  align-items: center;
  margin: 0 25px;
  perspective: 100px;
}
.slider .slide img {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  transition: transform 1s;
  border: 5px solid var(--color-peace);
}
.slider .slide img:hover {
  transform: translateZ(20px);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-350px * 5));
  }
}
.slider::before {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
  left: 0;
  top: 0;
}
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
  right: 0;
  top: 0;
  transform: rotate(180deg);
}
