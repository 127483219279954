.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
  /* border: solid yellow 2px; */
  width: 100%;
}
.outer:hover .card {
  animation-play-state: paused;
}

.outer .card {
  background: var(--color-white);
  width: 430px;
  display: flex;
  align-items: center;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  justify-content: space-between;
  border-radius: 100px 0 80px 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  animation: animate 15s linear infinite;
  animation-delay: calc(3s * var(--delay));
  height: 6rem;
  width: 50%;
}
.outer .card:last-child {
  animation-delay: calc(-3s * var(--delay));
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(100%) scale(0.5);
  }
  5% {
    opacity: 0.4;
    transform: translateY(100%) scale(0.7);
  }
  20% {
    opacity: 0.4;
    transform: translateY(100%) scale(0.7);
  }
  25% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(1);
  }
  40% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(1);
  }
  45% {
    opacity: 0.4;
    transform: translateY(-100%) scale(0.7);
  }
  60% {
    opacity: 0.4;
    transform: translateY(-100%) scale(0.7);
  }
  65% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
}
.outer .card__content {
  display: flex;
  align-items: center;
  width: 100%;
  /* border: solid red 2px; */
}
.outer .card__content .img {
  height: 90px;
  width: 90px;
  position: absolute;
  left: -5px;
  background: var(--color-white);
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.outer .card__content .img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.outer .details {
  margin-left: 80px;
  padding-top: 2rem;
}
.outer .details span {
  font-weight: 600;
  font-size: 18px;
  color: var(--color-fulfillment);
}

.outer .details a {
  text-decoration: none;
  padding: 7px 18px;
  border-radius: 25px;
  color: var(--color-white);
  background: linear-gradient(
    135deg,
    var(--color-confidence),
    var(--color-joy-variant)
  );
  transition: all 0.3s ease;
}
.outer .details a:hover {
  transform: scale(0.94);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .outer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border: solid red 2px;
  }
  .outer:hover .card {
    animation-play-state: paused;
  }
  .outer .card {
    background: var(--color-white);
    display: flex;
    align-items: center;
    padding: 20px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    justify-content: space-between;
    border-radius: 100px 0 80px 100px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    animation: animate 15s linear infinite;
    animation-delay: calc(3s * var(--delay));
    height: 6rem;
    width: 100%;
    /* border: solid red 2px; */
  }

  .outer .card__content {
    display: flex;
    align-items: center;
  }
  .outer .card__content .img {
    height: 90px;
    width: 90px;
    position: absolute;
    left: -5px;
    background: var(--color-white);
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  .outer .card__content .img img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .outer .details {
    margin-left: 80px;
  }
  .outer .details span {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-fulfillment);
  }
}
