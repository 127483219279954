#contact-wrapper {
  padding: 4rem 0;
}
#contact-wrapper .container {
  display: flex;
  gap: 2rem;
}
#contact-wrapper .container .left {
  width: 40%;
}
#contact-wrapper .container .right {
  width: 60%;
}
#contact-wrapper .container .right form input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

#contact-wrapper .container .right form textarea {
  height: 200px;
}

#contact-wrapper .container .right form input[type="submit"],
.sub-btn {
  background-color: var(--color-confidence);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: all 400ms ease;
}

#contact-wrapper .container .right form input[type="submit"]:hover,
.sub-btn:hover {
  opacity: 0.8;
  box-shadow: 0 1rem 1.6rem rgba(#191919, 0.15);
  transform: scale(0.94);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  #contact-wrapper {
    padding: 4rem 0;
  }
  #contact-wrapper .container {
    display: flex;
    flex-direction: column;
  }
  #contact-wrapper .container .left {
    width: 100%;
  }
  #contact-wrapper .container .right {
    width: 100%;
  }
}
