.eventCard {
  min-height: 20vh;
  margin-bottom: 4rem;
}
.eventCard .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eventCard .header h3 {
  text-transform: capitalize;
  font-family: days one;
  text-align: center;
  margin-bottom: 1.2rem;
}

.eventCard .cardsWrapper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.search-bar {
  background-color: #ddd;
  padding: 15px;
  border-radius: 5px;
  width: 50%;
}

.eventCard .cardsWrapper .card {
  background-color: var(--color-confidence);
  height: 8rem;
  display: flex;
  flex-direction: row;
  transition: var(--transition);
}

.eventCard .cardsWrapper .card:hover {
  transform: scale(0.94);
}

.eventCard .cardsWrapper .card .date {
  width: 20%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  text-transform: capitalize;
  font-family: days one;
  line-height: 1.1;
  letter-spacing: 1px;
  /* border: solid green 2px; */
}

.eventCard .cardsWrapper .card .date .day {
  font-size: 3rem;
}
.eventCard .cardsWrapper .card .date .month {
  font-size: x-small;
}

.eventCard .cardsWrapper .card .date .year {
  font-size: large;
}

.eventCard .cardsWrapper .card .info {
  width: 80%;
  display: flex;
  /* gap: 2rem; */
  /* border: solid yellow 2px; */
}

.eventCard .cardsWrapper .card .info .image {
  width: 30%;
  height: 8rem;
  /* border: solid green 2px; */
}

.eventCard .cardsWrapper .card .info .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 3/2;
}

.eventCard .cardsWrapper .card .info .details {
  padding: 1rem 4%;
  /* padding-right: 2%; */
  color: var(--color-white);
  width: 70%;
  /* border: solid green 2px; */
}

.eventCard .cardsWrapper .card .info .details .title h2 {
  text-transform: capitalize;
  font-family: days one;
  font-size: large;
  font-weight: 200;
  letter-spacing: 1px;
  text-align: left;
}

.eventCard .cardsWrapper .card .info .details p {
  text-transform: capitalize;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  margin-top: 1.5rem;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 3px;
  color: #333;
  text-decoration: none;
}

.pagination .active a {
  background-color: var(--color-confidence);
  color: #fff;
}

.pagination a:hover {
  background-color: #ddd;
}

@media screen and (max-width: 600px) {
  .eventCard .cardsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .eventCard .cardsWrapper .card .date .day {
    font-size: 2rem;
  }

  .eventCard .cardsWrapper .card .info .details {
    padding: 1rem 2%;
  }

  .eventCard .cardsWrapper .card .info .details .title h2 {
    font-size: small;
  }

  .eventCard .cardsWrapper .card .info .details p {
    padding-bottom: 1rem;
  }
}
