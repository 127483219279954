#aboutUs {
  padding: 8rem 0 2rem 0;
}
#aboutUs .container .aboutUs-content h1 {
  text-transform: capitalize;
  font-size: 3rem;
  color: var(--color-fulfillment);
}
#aboutUs article p {
  margin: 1rem 0 1rem 0;
}
#aboutUs article h3 {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
#aboutUs article ul {
  margin-left: 1rem;
}
#aboutUs article ul li {
  list-style: disc;
}

#aboutUs .mission {
  background-color: var(--color-confidence);
  color: var(--color-white);
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
  border-radius: 1rem;
}
#aboutUs .vision {
  background-color: var(--color-joy);
  color: (--color-fulfillment);
  padding: 1rem;
  text-align: center;
  border-radius: 1rem;
}
