header {
  height: 100vh;
  display: flex;
  place-items: center;
  overflow: hidden;
  position: relative;
}
header .header__bg-vid {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  flex-flow: column;
  text-align: center;
  /* margin-top: -7rem; */
  overflow: hidden !important;
  z-index: -1;
  object-fit: cover;
}
header .container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 46% 46%;
  align-items: center;
  gap: 8%;
  justify-content: space-between;
}
header .container .header__content h1 {
  font-size: 3.5rem;
  letter-spacing: -3px;
  line-height: 1.1;
  font-weight: 300;
}
header .container .header__content p {
  margin: 1rem 0 2rem;
  color: var(--light-color);
  font-size: 1.1rem;
}

.header__wave {
  position: absolute;
  bottom: -2rem;
  left: 0;
  height: 11rem;
  width: 100vw;
  background: url("../../assets/images/header__wave.png");
  background-size: 100vw 11rem;
  background-repeat: no-repeat;
}

#feet {
  min-height: 100vh;
  background: linear-gradient(
    var(--color-confidence),
    var(--color-joy-variant)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  padding: 0 1rem;
}
#feet .logo__mark {
  display: flex;
  flex-direction: column;
  background: url("../../assets/images/logomark.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
#feet .container h2 {
  color: var(--color-white);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: days one;
  text-transform: capitalize;
}
#feet .container p {
  color: var(--color-white);
  padding: 0 10rem;
  text-align: left;
}

#about {
  background: var(--color-joy-variant);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  min-height: 400px;
  border-bottom-right-radius: 10rem;
}
#about .container {
  margin-bottom: 3rem;
}
#about .container article h2 {
  color: var(--color-white);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: days one;
}
#about .container article h3 {
  color: var(--color-white);
  margin: 1rem 0;
  font-weight: 400;
}
#about .container article p {
  color: var(--color-white);
  padding: 0 10rem;
  text-align: left;
}
#about .container article a {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.nextEvents {
  min-height: 60vh;
  padding: 4rem 0;
}

.nextEvents .container .btnHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 2rem 0;
}

.nextEvents .container .btnHolder .btnPrimary {
  background-color: var(--color-joy-variant);
}

#past-events {
  margin-bottom: 6rem;
}
#past-events .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}
#past-events .header {
  margin-top: 2rem;
}
#past-events .header h2 {
  text-transform: capitalize;
  color: gray;
  font-size: 2.5rem;
  font-weight: 300;
}

#past-events .coming-event {
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  background: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0 1.5rem 1.5rem rgba(var(--color-black), 0.2);
  position: relative;
  transition: all 700ms ease;
  position: relative;
  min-height: 25rem;
  overflow: hidden;
}
#past-events .coming-event:nth-child(3) article .event-image img {
  height: 25rem;
}

#past-events .coming-event article {
  display: flex;
  flex-direction: row;
  gap: 3%;
}
#past-events .event-image {
  height: 25rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  flex-basis: 40%;
}
#past-events .event-image img {
  filter: saturate(0);
  object-fit: cover;
  height: 25rem;
}
#past-events .event-image img:hover {
  filter: unset;
}

#past-events .event-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 60%;
  padding: 2rem 5%;
}
#past-events .event-details div {
  width: 95%;
}
#past-events .event-details .event-date {
  color: grey;
}
#past-events .event-details h2 {
  margin: 0.5rem 0;
  font-size: xx-large;
  color: var(--color-bg);
  text-transform: capitalize;
}
#past-events .event-details p {
  margin-bottom: 1rem;
}

#founder {
  background: var(--color-happiness);
}
#founder .founder__image {
  background: url("./../../assets/images/fndafull.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 80vh;
}
#founderText {
  background: var(--color-fulfillment);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  min-height: 400px;
}
#founderText .container article div h2 {
  color: var(--color-white);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: days one;
}
#founderText .container p {
  color: var(--color-white);
  padding: 0 10rem;
  text-align: left;
}
#founderText .container a {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

#volunteer {
  background: var(--color-fulfillment);
  min-height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;
  position: relative;
}
#volunteer .container {
  margin-top: 3rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 46% 46%;
  align-items: center;
  gap: 8%;
  justify-content: space-between;
}
#volunteer .volunteer__content h1 {
  color: var(--color-joy);
  font-size: 3.5rem;
  line-height: 1.1;
  font-weight: 300;
  text-align: left;
  text-transform: capitalize;
}
#volunteer .volunteer__content p {
  margin: 1rem 0 2rem;
  color: var(--color-white);
  font-size: 1.1rem;
  text-align: left;
}

#volunteer .volunteer__image img {
  -webkit-mask-image: url(data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMjQ4LCAxMTcsIDU1LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MSwgMTY4LCAzMSwgMSkiIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InVybCgjc3ctZ3JhZGllbnQpIiBkPSJNMjQuNiwtMjcuN0MzMi40LC0yMi43LDM5LjYsLTE1LjUsNDEuMSwtNy4yQzQyLjUsMSwzOC4yLDEwLjMsMzIuOSwxOC41QzI3LjYsMjYuNywyMS4zLDMzLjksMTMsMzcuOEM0LjgsNDEuNywtNS40LDQyLjMsLTEyLjgsMzguM0MtMjAuMiwzNC4zLC0yNC44LDI1LjYsLTI5LjUsMTcuMkMtMzQuMiw4LjgsLTM5LDAuNSwtMzguNSwtNy41Qy0zOCwtMTUuNiwtMzIuMSwtMjMuNCwtMjQuOCwtMjguNUMtMTcuNSwtMzMuNSwtOC44LC0zNS42LC0wLjIsLTM1LjRDOC40LC0zNS4yLDE2LjgsLTMyLjYsMjQuNiwtMjcuN1oiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=);
  -webkit-mask-repeat: no-repeat;
}

#volunteer .volunteer__wave {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 11rem;
  width: 100vw;
  background: url("./../../assets/images/wave2bbbbb.png");
  background-size: 100vw 11rem;
  background-repeat: no-repeat;
}
#team {
  min-height: 70vh;
  background: var(--color-confidence);
}
#team h2 {
  color: var(--color-white);
  font-size: 3rem;
  letter-spacing: -3px;
  line-height: 1.1;
  font-weight: 300;
  padding-top: 3rem;
}
#team .container {
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .header__wave {
    position: absolute;
    bottom: -2rem;
    left: 0;
    height: 11rem;
    width: 100vw;
    background: url("../../assets/images/header__wave.png");
    background-size: 100vw 11rem;
    background-repeat: no-repeat;
  }

  #feet {
    flex-direction: column;
    min-height: 60vh;
    /* border: solid blueviolet 2px; */
  }

  #feet .logo__mark {
    flex-direction: column;
    min-height: 60vh;
    /* border: solid green 2px; */
  }
  #feet .container h2 {
    color: var(--color-white);
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: days one;
    text-transform: capitalize;
  }
  #feet .container p {
    color: var(--color-white);
    padding: 0;
    text-align: left;
    /* width: 100%; */
    /* border: solid green 2px; */
  }

  #about {
    flex-direction: column;
  }

  /* #about .container article {
    border: solid yellow 2px;
  } */

  #about .container article p {
    padding: 0;

    width: 100%;
  }
  #about .container article a {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .nextEvents {
    min-height: 60vh;
    padding: 4rem 0;
  }

  .nextEvents .container .btnHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 2rem 0;
  }

  #founder .founder__image {
    background-position: -43rem;

    background-attachment: fixed;
    height: 40vh;
  }
  #founderText {
    min-height: 600px;
    /* border: solid red 2px; */
  }

  #founderText .container p {
    padding: 0;
  }
  #founderText .container a {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  #volunteer {
    display: flex;
    flex-direction: column;
  }
  #volunteer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #team {
    min-height: 70vh;
    background: var(--color-confidence);
  }

  #team .container {
    min-height: 50vh;
    flex-direction: column;
  }
}
